import axios from "axios"
import serverConfig from './config'
import router from '../router'
import {
	ElMessage
} from 'element-plus'

let whiteList = ['/v1/captcha', '/admin/auth/token'] //不需要设置token的白名单

//创建axios实例
const serverAxios = axios.create({
	baseURL: serverConfig.baseURL //请求地址
})

//请求拦截
serverAxios.interceptors.request.use((config) => {
		//设置请求头
		if (whiteList.findIndex(item => item === config.url) === -1) {
			let scheme = sessionStorage.getItem('scheme')
			//let tenantCode = sessionStorage.getItem('tenantCode')
			let token = sessionStorage.getItem('token')
			config.headers["Authorization"] = scheme + token
			//config.headers["Tenant-Code"] = tenantCode
		}
		if (!config.headers["content-type"]) {
			//如果没有设置请求头 则默认 json
			config.headers["content-type"] = "application/json"
		}
		return config
	},
	(error) => {
		Promise.reject(error)
	})
//响应拦截
serverAxios.interceptors.response.use((res) => {
		if (res.config.url != '/v1/captcha') {
			if (res.data.code !== 200) {
				if (res.data.code === 401) {
					ElMessage({
						type: 'error',
						message: "登录信息过期！请重新登录！",
					})
					//登录信息有误或过期 跳转login页面
					router.replace({
						path: '/'
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message,
					})
				}
			}
		}
		return res.data
	},
	(error) => {
		let message = ''
		if (error && error.response) {
			switch (error.response.status) {
				case 404:
					message = '请求地址未找到'
					break
				case 401:
					message = '用户登录信息过期'
					//登录信息有误或过期 跳转login页面
					router.replace({
						path: '/'
					})
					break
				default:
					message = '服务器异常'
					break
			}
		}
		ElMessage({
			type: 'error',
			message: message,
		})
		return Promise.reject(message)
	})

export default serverAxios
