import http from './http'

/**
 * 获取验证码
 */
export const logincode = (params) => {
	return http({
		method: 'GET',
		url: '/v1/captcha',
		responseType: "arraybuffer",
		params
	})
}
/**
 * 登录
 */
export const logintoken = (data) => {
	return http({
		method: 'POST',
		url: '/admin/auth/token',
		data
	})
}
/**
 * 获取学科列表（分页）
 */
export const subjectslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/subjects/list',
		params
	})
}
/**
 * 添加学科
 */
export const subjectsadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/subjects/add',
		data
	})
}
/**
 * 编辑学科
 */
export const subjectsedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/subjects/edit',
		data
	})
}
/**
 * 删除学科
 */
export const subjectsdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/subjects/' + id
	})
}
/**
 * 年级列表（分页）
 */
export const gradeslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/grades/list',
		params
	})
}
/**
 * 年级添加
 */
export const gradesadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/grades/add',
		data
	})
}
/**
 * 年级修改
 */
export const gradesedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/grades/edit',
		data
	})
}
/**
 * 年级删除
 */
export const gradesdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/grades/' + id
	})
}
/**
 * 学期列表（分页）
 */
export const semesterslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/semesters/list',
		params
	})
}
/**
 * 学期添加
 */
export const semestersadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/semesters/add',
		data
	})
}
/**
 * 学期修改
 */
export const semestersedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/semesters/edit',
		data
	})
}
/**
 * 学期删除
 */
export const semestersdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/semesters/' + id
	})
}
/**
 * 出版社列表（分页）
 */
export const presseslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/presses/list',
		params
	})
}
/**
 * 出版社添加
 */
export const pressesadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/presses/add',
		data
	})
}
/**
 * 出版社修改
 */
export const pressesedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/presses/edit',
		data
	})
}
/**
 * 出版社删除
 */
export const pressesdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/presses/' + id
	})
}
/**
 * 单元列表（分页）
 */
export const unitlist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/unit/list',
		params
	})
}
/**
 * 单元添加
 */
export const unitadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/unit/add',
		data
	})
}
/**
 * 单元修改
 */
export const unitedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/unit/edit',
		data
	})
}
/**
 * 单元删除
 */
export const unitdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/unit/' + id
	})
}
/**
 * 租户列表（分页）
 */
export const tenantslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/tenants/list',
		params
	})
}
/**
 * 租户添加
 */
export const tenantsadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/tenants/add',
		data
	})
}
/**
 * 租户修改
 */
export const tenantsedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/tenants/edit',
		data
	})
}
/**
 * 租户删除
 */
export const tenantsdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/tenants/' + id
	})
}

/**
 * 量规列表（分页）
 */
export const rubricslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/rubrics/list',
		params
	})
}
/**
 * 量规列表树形
 */
export const rubricstree = (params) => {
	return http({
		method: 'GET',
		url: '/admin/rubrics/tree',
		params
	})
}
/**
 * 量规添加
 */
export const rubricsadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/rubrics/add',
		data
	})
}
/**
 * 量规修改
 */
export const rubricsedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/rubrics/edit',
		data
	})
}
/**
 * 量规删除
 */
export const rubricsdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/rubrics/' + id
	})
}
/**
 * 量规分类列表（分页）
 */
export const rubriccategorieslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/rubric-categories/list',
		params
	})
}
/**
 * 量规分类添加
 */
export const rubriccategoriesadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/rubric-categories/add',
		data
	})
}
/**
 * 量规分类修改
 */
export const rubriccategoriesedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/rubric-categories/edit',
		data
	})
}
/**
 * 量规分类删除
 */
export const rubriccategoriesdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/rubric-categories/' + id
	})
}
/**
 * 知识点分类列表（分页）
 */
export const knowledgecategoreislist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/knowledge-categoreis/list',
		params
	})
}
/**
 * 知识点分类添加
 */
export const knowledgecategoreisadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/knowledgecategoreis/add',
		data
	})
}
/**
 * 知识点分类修改
 */
export const knowledgecategoreisedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/knowledgecategoreis/edit',
		data
	})
}
/**
 * 知识点分类删除
 */
export const knowledgecategoreisdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/knowledgecategoreis/' + id
	})
}
/**
 * 知识点列表（分页）
 */
export const knowledgelist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/knowledge/list',
		params
	})
}
/**
 * 知识点列表（树）
 */
export const knowledgetree = (params) => {
	return http({
		method: 'GET',
		url: '/admin/knowledge/tree',
		params
	})
}
/**
 * 知识点添加
 */
export const knowledgeadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/knowledge/add',
		data
	})
}
/**
 * 知识点修改
 */
export const knowledgeedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/knowledge/edit',
		data
	})
}
/**
 * 知识点删除
 */
export const knowledgedelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/knowledge/' + id
	})
}
/**
 * 题目列表（分页）
 */
export const questionslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/questions/list',
		params
	})
}
/**
 * 题目详情通过ID查询
 */
export const questionsquery = (id) => {
	return http({
		method: 'GET',
		url: '/admin/questions/tree/' + id
	})
}
/**
 * 题目添加
 */
export const questionsadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/questions/add',
		data
	})
}
/**
 * 题目修改
 */
export const questionsedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/questions/edit',
		data
	})
}
/**
 * 题目删除
 */
export const questionsdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/questions/' + id
	})
}
/**
 * 认知层级列表（分页）
 */
export const cognitivelevellist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/cognitive-level/list',
		params
	})
}
/**
 * 认知层级添加
 */
export const cognitiveleveladd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/cognitive-level/add',
		data
	})
}
/**
 * 认知层级修改
 */
export const cognitiveleveledit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/cognitive-level/edit',
		data
	})
}
/**
 * 认知层级删除
 */
export const cognitiveleveldelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/cognitive-level/' + id
	})
}


/**
 * 单张图片上传
 */
export const attachmentsupload = (data) => {
	let scheme = sessionStorage.getItem('scheme')
	let token = sessionStorage.getItem('token')
	return http({
		method: 'POST',
		url: '/admin/attachments/upload',
		data: data,
		headers: {
			"Authorization": scheme + token,
			"content-type": "multipart/form-data"
		},
		responseType: "json"
	})
}
/**
 * 多张图片上传
 */
export const attachmentsuploads = (data) => {
	let scheme = sessionStorage.getItem('scheme')
	let token = sessionStorage.getItem('token')
	return http({
		method: 'POST',
		url: '/admin/attachments/uploads',
		data: data,
		headers: {
			"Authorization": scheme + token,
			"content-type": "multipart/form-data"
		},
		responseType: "json"
	})
}
/**
 * 作业列表（分页）
 */
export const homeworklist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/homework/list',
		params
	})
}
/**
 * 作业添加
 */
export const homeworkadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/homework/addquestionIds',
		data
	})
}
/**
 * 作业修改
 */
export const homeworkedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/homework/edit',
		data
	})
}
/**
 * 作业删除
 */
export const homeworkdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/homework/' + id
	})
}
/**
 * 作业详情
 */
export const homeworkinfo = (id) => {
	return http({
		method: 'GET',
		url: '/admin/homework/' + id
	})
}

/**
 * 设置自定义题号
 */
export const homeworksetnum = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/homeworkItem/updateQuestionNumber',
		data
	})
}


/**
 * 套题列表（分页）
 */
export const setlist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/setlist/list',
		params
	})
}
/**
 * 套题添加
 */
export const setlistadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/setlist/add',
		data
	})
}
/**
 * 套题修改
 */
export const setlistedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/setlist/edit',
		data
	})
}
/**
 * 套题删除
 */
export const setlistdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/setlist/' + id
	})
}

/**
 * 获取老师列表 无分页  有条件
 */
export const queryteacher = (params) => {
	return http({
		method: 'GET',
		url: '/admin/teacher/listall',
		params
	})
}

/**
 * 分配作业给老师
 */
export const homeworkaddteachers = (data) => {
	return http({
		method: 'POST',
		url: '/admin/homework-teachers',
		data
	})
}


/**
 * 老师列表（分页）
 */
export const teacherlist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/teacher/list',
		params
	})
}
/**
 * 老师添加
 */
export const teachercreat = (data) => {
	return http({
		method: 'POST',
		url: '/admin/teacher/creat',
		data
	})
}
/**
 * 用户修改
 */
export const usersedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/users/edit',
		data
	})
}
/**
 * 用户删除
 */
export const usersdelete = (data) => {
	return http({
		method: 'POST',
		url: '/admin/users/delete-batch',
		data
	})
}

/**
 * 班级列表
 */
export const classroomslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/classrooms/list',
		params
	})
}


/**
 * 学生列表（分页）
 */
export const studentlist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/student/list',
		params
	})
}
/**
 * 学生添加
 */
export const studentadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/student/add',
		data
	})
}
/**
 * 学生修改
 */
export const studentedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/student/edit',
		data
	})
}
/**
 * 学生删除
 */
export const studentdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/student/' + id
	})
}


/**
 * 课程列表
 */
export const courseslist = (params) => {
	return http({
		method: 'GET',
		url: '/admin/courses/list',
		params
	})
}

/**
 * 添加课程主体
 */
export const coursesadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/courses/add',
		data
	})
}
/**
 * 删除课程
 */
export const deletecourses = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/courses/delete/' + id
	})
}
/**
 * 修改课程主体
 */
export const coursesedit = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/courses/edit',
		data
	})
}

/**
 * 课程详情
 */
export const coursesinfo = (id) => {
	return http({
		method: 'GET',
		url: '/admin/courses/' + id
	})
}

/**
 * 在课程下增加章节
 */
export const chaptersadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/chapters/add',
		data
	})
}

/**
 * 在课程下增加课时
 */
export const lessonsadd = (data) => {
	return http({
		method: 'POST',
		url: '/admin/lessons/add',
		data
	})
}

/**
 * 删除章节
 */
export const chaptersdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/chapters/delete/' + id
	})
}
/**
 * 删除课时
 */
export const lessonsdelete = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/lessons/delete/' + id
	})
}
/**
 * 校级菜单树
 */
export const menutreeschool = (params) => {
	return http({
		method: 'GET',
		url: '/admin/list/tree',
		params
	})
}
/**
 * 校级菜单添加  
 */
export const menusaddschool = (data) => {
	return http({
		method: 'POST',
		url: '/admin/menus/add',
		data
	})
}
/**
 * 校级菜单删除
 */
export const menusdeleteschool = (id) => {
	return http({
		method: 'DELETE',
		url: '/admin/menus/delete/' + id
	})
}
/**
 * 校级菜单列表 不含按钮  
 */
export const menutreeschoolnobtn = (params) => {
	return http({
		method: 'GET',
		url: '/admin/list/menu/tree',
		params
	})
}
/**
 * 校级菜单 修改
 */
export const menuseditschool = (data) => {
	return http({
		method: 'PUT',
		url: '/admin/menus/edit',
		data
	})
}
/**
 * 角色列表
 */
export const roleslist = () => {
	return http({
		method: 'GET',
		url: '/admin/roles/list'
	})
}